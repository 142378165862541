<template>
	<div class="product-category">
		<v-row no-gutters dense class="d-flex" style="height: inherit">
			<v-col cols="12" class="col-md-12 pt-8 my-pr">
				<v-card class="d-flex flex-column my-card">
					<v-row no-gutters dense class="d-flex align-start flex-grow-0 pb-10">
						<v-col cols="12">
							<p class="heading-sf20">Choose products categories</p>
						</v-col>
					</v-row>
					<v-row v-if="$store.state.setting.distributor.role === 'A'" no-gutters dense class="d-flex flex-grow-0">
						<!-- <v-col cols="12" class="d-flex"> -->
						<div
							class="category-box d-flex"
							v-bind:class="{ 'select-category': category.lumber }"
							v-on:click=";(category.lumber = !category.lumber), (valid = true)"
						>
							<span class="category-icon d-flex align-center justify-center">
								<img :src="$store.state.icons.icons['Lumber']" alt />
							</span>
							<span class="category-text d-flex align-center justify-center">Lumber</span>
						</div>

						<div
							class="category-box d-flex mr-sm-4 mr-0"
							v-bind:class="{ 'select-category': category.electric }"
							v-on:click=";(category.electric = !category.electric), (valid = true)"
						>
							<span class="category-icon d-flex align-center justify-center">
								<img :src="$store.state.icons.icons['Electric']" alt />
							</span>
							<span class="category-text d-flex align-center justify-center">Electric</span>
						</div>

						<div
							class="category-box d-flex"
							v-bind:class="{ 'select-category': category.plumbing }"
							v-on:click=";(category.plumbing = !category.plumbing), (valid = true)"
						>
							<span class="category-icon d-flex align-center justify-center">
								<img :src="$store.state.icons.icons['Plumbing']" alt />
							</span>
							<span class="category-text d-flex align-center justify-center">Plumbing</span>
						</div>

						<div
							class="category-box d-flex mr-sm-4 mr-0"
							v-bind:class="{ 'select-category': category.masonry }"
							v-on:click=";(category.masonry = !category.masonry), (valid = true)"
						>
							<span class="category-icon d-flex align-center justify-center">
								<img :src="$store.state.icons.icons['Masonry']" alt />
							</span>
							<span class="category-text d-flex align-center justify-center">Masonry</span>
						</div>

						<div
							class="category-box d-flex"
							v-bind:class="{ 'select-category': category.general }"
							v-on:click=";(category.general = !category.general), (valid = true)"
						>
							<span class="category-icon d-flex align-center justify-center">
								<img :src="$store.state.icons.icons['ToolBoxes']" alt />
							</span>
							<span class="category-text d-flex align-center justify-center">General</span>
						</div>
						<div
							class="category-box d-flex"
							v-bind:class="{ 'select-category': category.landscape }"
							v-on:click=";(category.landscape = !category.landscape), (valid = true)"
						>
							<span class="category-icon d-flex align-center justify-center">
								<img :src="$store.state.icons.icons['Landscape']" alt />
							</span>
							<span class="category-text d-flex align-center justify-center">Landscape</span>
						</div>
						<!-- </v-col> -->
					</v-row>
					<v-row v-else no-gutters dense class="d-flex flex-grow-0">
						<!-- <v-col cols="12" class="d-flex"> -->
						<div class="category-box d-flex" v-bind:class="{ 'select-category': category.lumber }">
							<span class="category-icon d-flex align-center justify-center">
								<img :src="$store.state.icons.icons['Lumber']" alt />
							</span>
							<span class="category-text d-flex align-center justify-center">Lumber</span>
						</div>

						<div class="category-box d-flex" v-bind:class="{ 'select-category': category.electric }">
							<span class="category-icon d-flex align-center justify-center">
								<img :src="$store.state.icons.icons['Electric']" alt />
							</span>
							<span class="category-text d-flex align-center justify-center">Electric</span>
						</div>

						<div class="category-box d-flex" v-bind:class="{ 'select-category': category.plumbing }">
							<span class="category-icon d-flex align-center justify-center">
								<img :src="$store.state.icons.icons['Plumbing']" alt />
							</span>
							<span class="category-text d-flex align-center justify-center">Plumbing</span>
						</div>

						<div class="category-box d-flex" v-bind:class="{ 'select-category': category.masonry }">
							<span class="category-icon d-flex align-center justify-center">
								<img :src="$store.state.icons.icons['Masonry']" alt />
							</span>
							<span class="category-text d-flex align-center justify-center">Masonry</span>
						</div>

						<div class="category-box d-flex" v-bind:class="{ 'select-category': category.general }">
							<span class="category-icon d-flex align-center justify-center">
								<img :src="$store.state.icons.icons['ToolBoxes']" alt />
							</span>
							<span class="category-text d-flex align-center justify-center">General</span>
						</div>

						<div class="category-box d-flex" v-bind:class="{ 'select-category': category.landscape }">
							<span class="category-icon d-flex align-center justify-center">
								<img :src="$store.state.icons.icons['Landscape']" alt />
							</span>
							<span class="category-text d-flex align-center justify-center">Landscape</span>
						</div>
						<!-- </v-col> -->
					</v-row>
					<v-row no-gutters dense align="stretch" class="d-flex">
						<v-col v-if="error_message != ''" class="d-flex align-center mt-auto p-0 custom-error" cols="12">{{ this.error_message }}</v-col>
						<v-col cols="12" class="d-flex justify-end mt-auto">
							<v-btn
								class="my-auto-btn my_elevation"
								id="my_elevation"
								@click="productCategory()"
								:disabled="!valid || $store.state.setting.distributor.role === 'AU'"
								depressed
								>Save changes</v-btn
							>
						</v-col>
					</v-row>
				</v-card>
			</v-col>
		</v-row>
	</div>
</template>

<script>
// import { mask } from 'vue-the-mask'
import { updateCategory } from '../../services/settingservice'
require('../../scss/product.scss')
export default {
	data() {
		return {
			active_tab: false,
			show: true,
			valid: true,
			error_message: '',
			category: {
				general: false,
				electric: false,
				lumber: false,
				masonry: false,
				plumbing: false,
				landscape: false,
			},
			category_data: {
				category_id: [],
				company_id: '',
			},
		}
	},

	created() {
		this.$store.watch(
			(state) => {
				return state.auth.error
			},
			() => {
				if (this.active_tab === true) {
					this.error_message = this.$store.state.auth.error
				}
			},
			{ deep: true }
		)

		this.$store.watch(
			(state) => {
				return state.setting.category, state.setting.setting_tab
			},
			() => {
				if (this.$store.state.setting.setting_tab.tab === 'product_category') {
					let data = this.$store.state.setting.category
					this.myCategory(data)
				}
			},
			{ deep: true }
		)
	},

	async mounted() {
		let data = this.$store.state.setting.category
		this.myCategory(data)
	},

	computed: {
		Show() {
			return (this.show = !this.valid ? false : true)
		},
	},

	methods: {
		myCategory(data) {
			this.error_message = ''
			this.category.general = false
			this.category.electric = false
			this.category.lumber = false
			this.category.masonry = false
			this.category.plumbing = false
			this.category.landscape = false
			let category = data.category_id

			if (category !== null) {
				if (category.includes(1)) {
					this.category.lumber = true
				}
				if (category.includes(2)) {
					this.category.electric = true
				}
				if (category.includes(3)) {
					this.category.plumbing = true
				}
				if (category.includes(4)) {
					this.category.masonry = true
				}
				if (category.includes(5)) {
					this.category.general = true
				}
				if (category.includes(6)) {
					this.category.landscape = true
				}
			}
		},

		async productCategory() {
			if (
				this.category.general === true ||
				this.category.electric === true ||
				this.category.lumber === true ||
				this.category.plumbing === true ||
				this.category.masonry === true ||
				this.category.landscape === true
			) {
				this.error_message = ''
				this.$store.commit('errorMessage', '')
				this.active_tab = true
				this.category_data.category_id = []
				this.category_data.company_id = this.$store.state.auth.user_data.company_id

				this.category.lumber === true ? this.category_data.category_id.push(1) : ''
				this.category.electric === true ? this.category_data.category_id.push(2) : ''
				this.category.plumbing === true ? this.category_data.category_id.push(3) : ''
				this.category.masonry === true ? this.category_data.category_id.push(4) : ''
				this.category.general === true ? this.category_data.category_id.push(5) : ''
				this.category.landscape === true ? this.category_data.category_id.push(6) : ''
				let data = await updateCategory(this.category_data)

				if (!data.data) {
					this.error_message = this.$store.state.auth.error
				} else {
					this.$store.commit('categoryInfo', data.data)
					this.myCategory(data.data)
					this.active_tab = false
				}
			} else {
				this.valid = false
				this.active_tab = true
				this.error_message = 'Please select minimum one category'
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.product-category {
	height: 100%;
}
@media only screen and (max-width: 600px) {
	.category-box {
		width: 110px !important;
	}
}
</style>
